import { OnboardingProps } from "types";
import React from "react";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Error from "components/Shared/Error";
import SubTitle from "components/Shared/SubTitle";
import Title from "components/Shared/Title";
import Stepper from "components/Shared/Stepper";
import Autocomplete from "components/Shared/Autocomplete";
import Select from "components/Shared/Select";

const Onboarding = (props: OnboardingProps): React.ReactElement => {
  const {
    onboardingForm,
    handleFormChange,
    handleFormSubmit,
    error,
    formIsValid,
    loaders,
    businessTypes,
    currencies
  } = props;

  return (
    <div className="w-screen h-screen pl-0 md:pl-12 lg:pl-40">
      <header className="pr-0 md:pr-12 lg:pr-40 pt-6 sm:pt-10 w-full flex flex-col lg:flex-row">
        <img src="/logo.svg" alt="Bloom Logo" className="w-32 sm:w-44 mb-2 lg:mb-0 ml-4 sm:ml-0" />
        <Stepper
          activeStep="Company details"
          steps={["Sign up", "Company details", "Get started"]}
        />
      </header>
      <div className="flex justify-center mx-4 sm:mx-0 mt-14 sm:mt-20">
        <div className="bg-white rounded-sm px-4 py-10 md:p-20 sm:w-4/5 md:2/3 xl:w-1/2">
          <Title className="text-center">Welcome to BloomPulse!</Title>
          <SubTitle>Tell us about you and your business</SubTitle>
          <form onSubmit={handleFormSubmit} className="mt-10 sm:mt-16" data-testid="onboardingForm">
            <Error error={error} />

            <div className="w-full flex space-x-4">
              <div className="w-1/2">
                <TextInput
                  data-testid="firstName"
                  type="firstName"
                  name="firstName"
                  onChange={handleFormChange}
                  value={onboardingForm.firstName}
                  required
                  label="First name"
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  data-testid="lastName"
                  type="lastName"
                  name="lastName"
                  onChange={handleFormChange}
                  value={onboardingForm.lastName}
                  required
                  label="Last name"
                />
              </div>
            </div>
            <div>
              <TextInput
                data-testid="businessName"
                type="text"
                name="businessName"
                onChange={handleFormChange}
                value={onboardingForm.businessName}
                required
                label="Your business name"
              />
            </div>
            <div>
              <Autocomplete
                type="text"
                name="businessType"
                onChange={handleFormChange}
                value={onboardingForm.businessType}
                label="What does your business do?"
                data-testid="businessType"
                dataIdName="businessTypeId"
                data={businessTypes.map((businessType) => ({
                  id: businessType.id,
                  name: businessType.name
                }))}
                placeholder="Start typing to filter business types"
              />
              <small>
                {
                  businessTypes.find(
                    (businessType) => businessType.id == onboardingForm.businessType
                  )?.description
                }
              </small>
            </div>
            <div>
              <Autocomplete
                type="text"
                name="currency"
                onChange={handleFormChange}
                value={onboardingForm.currency}
                label="What currency do you operate in?"
                data-testid="currency"
                data={currencies.map((currency) => ({
                  id: currency.code,
                  name: `${currency.emoji} ${currency.name} (${currency.symbol})`
                }))}
                placeholder="Start typing to filter currencies"
                dataIdName="currencyCode"
              />
            </div>
            <div>
              <Select
                name="employees"
                value={onboardingForm.employees}
                required
                onChange={handleFormChange}
                data-testid="employees"
                label="How many employees does your business have?">
                <option value="" disabled>
                  Select option
                </option>
                <option value="Just you (solopreneur)">Just you (solopreneur)</option>
                <option value="2 - 5 employees (small team)">2 - 5 employees (small team)</option>
                <option value="5 - 20 employees (growing team)">
                  5 - 20 employees (growing team)
                </option>
                <option value="More than 20 employees  (large squad)">
                  More than 20 employees (large squad)
                </option>
              </Select>
            </div>
            <div>
              <Select
                name="howDidYouHear"
                value={onboardingForm.howDidYouHear}
                required
                onChange={handleFormChange}
                data-testid="howDidYouHear"
                label="How did you hear about us?">
                <option value="" disabled>
                  Select option
                </option>
                <option value="Someone referred me directly">Someone referred me directly</option>
                <option value="Youtube">Youtube </option>
                <option value="Instagram">Instagram </option>
                <option value="X (Twitter)">X (Twitter) </option>
                <option value="Search Engine">Search Engine (e.g., Google)</option>
                <option value="Others">Others</option>
              </Select>
            </div>
            {onboardingForm.howDidYouHear == "Others" && (
              <div>
                <TextInput
                  data-testid="others"
                  type="text"
                  name="others"
                  onChange={handleFormChange}
                  value={onboardingForm.others}
                  label="What other way did you hear about us?"
                />
              </div>
            )}
            <div>
              <Button
                data-testid="submitButton"
                type="submit"
                disabled={!formIsValid}
                loading={loaders.registeringBusiness}
                className="w-full mt-4">
                Submit details
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
